import style from "./../scss/main.scss";

global.$ = global.jQuery = require('jquery');

require('popper.js');
require('bootstrap');

require('bootstrap-select');
global.LazyLoad = require('vanilla-lazyload/dist/lazyload.min.js');

require('picturefill');

global.objectFitImages = require('object-fit-images');
require('fastselect');
global.Nette = require('nette-forms');
require('nette.ajax.js');
require('../../vendor/vojtech-dobes/nette-ajax-history/client-side/history.ajax.js');


import PNotify from 'pnotify/dist/umd/PNotify';
global.PNotify = PNotify;
global.PNotify.defaults.styling = 'bootstrap4';
global.tokenize2 = require('./tokenize2.js');

require('nodelist-foreach-polyfill');
require('classlist-polyfill');
